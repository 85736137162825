import React from "react";
import * as FontAwesome from "react-icons/fa";
import * as GameIcons from "react-icons/gi";
import * as MaterialDesign from "react-icons/md";
import * as Ionicons from "react-icons/io5";
import * as RemixIcons from "react-icons/ri";
import * as HeroIcons from "react-icons/hi"

export default function Icon(props) {
  const { iconName, size, color, className, animate } = props;
  let icon = "";
  let iconLib = iconName.substring(0, 2).toUpperCase();

  const iconClass = (className || "") + (animate === true ? " animate-spin absolute": "");

  switch(iconLib) {
    case "IO":
      icon = React.createElement(Ionicons[iconName], {style:{fontSize: size, color: color}, className: iconClass});
      break;
    case "MD":
      icon = React.createElement(MaterialDesign[iconName], {style:{fontSize: size, color: color}, className: iconClass});
      break;
    case "GI":
      icon = React.createElement(GameIcons[iconName], {style:{fontSize: size, color: color}, className: iconClass});
      break;
    case "HI":
      icon = React.createElement(HeroIcons[iconName], {style:{fontSize: size, color: color}, className: iconClass});
      break;
    case "RI":
      icon = React.createElement(RemixIcons[iconName], {style:{fontSize: size, color: color}, className: iconClass});
      break;
    case "FA":
    default:
      icon = React.createElement(FontAwesome[iconName], {style:{fontSize: size, color: color, verticalAlign: "middle"}, className: iconClass});
  }

  return icon;
};