import React from 'react';
import Hero from './components/Hero';
import Social from './components/Social';
import Cards from './components/Cards';
import Map from './components/Map';

export default function Content() {
  return (
    <div className="bg-theme-secondary">
      <Hero />
      <Social />
      <Cards />
      <Map />
    </div>
  );
}