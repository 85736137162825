import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../lib/loading";

export default function Gallery() {
  const bucketUrl = 'https://steelbounddunkirk-images.s3.us-east-2.amazonaws.com/';
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);

  //setting up options for API request to S3 bucket
  const opts = {
    method: "GET",
    url: bucketUrl,
    headers: {
      Accept: "*",
    },
  }

  useEffect(() => {
    setLoading(true);

    //Executing the API request on page load
    axios.create().request(opts).then(function(response) {
      const parser = new DOMParser();
      const xml = parser.parseFromString(response.data, 'text/xml');

      //grab the bucket return result and Contents nodes as this is where each
      // image in the bucket will be listed
      const contents = xml.getElementsByTagName('ListBucketResult')[0].getElementsByTagName('Contents');
      const imgArray = [];
      
      //check that we found images in the bucket and loop through creating array of image names
      if(contents && contents.length > 0){
        for (let index = 0; index < contents.length; index++) {
          const element = contents[index].childNodes[0].textContent;
          imgArray.push(element);
        };
      };

      // set the image name array in to state
      setResponseData(imgArray);
      setLoading(false);
    })
    .catch(function (error) {
      console.error(error);
      setLoading(false);
    });
  
  }, []);

  return (
    <section className="bg-theme-secondary-700 px-4 py-10 w-full">
      <div className="container grid grid-cols-2 gap-4 p-4 mx-auto md:grid-cols-4">

        {loading && 
          <Loading text="Getting images..." />
        }

        {(responseData === null || responseData.length === 0) && 
          <div className="text-white">
            Sorry, no images to display.
          </div>
        }

        {/* if there is data in state, iterate over it and draw the image */}
        {(responseData && responseData.length > 0) &&
          responseData.map((item, idx) => {
            return (
              <div key={idx}>
                <img src={bucketUrl + item} alt={'Steelbound Dunkirk ' + item} className="w-full h-full col-span-2 row-span-2 rounded shadow-sm min-h-96 md:col-start-3 md:row-start-1 aspect-square" />
              </div>
            )
          })
        }
      </div>
    </section>
  );
};