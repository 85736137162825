import React from "react";
import { Link } from "react-router-dom";
import rendering1 from "../assets/gallery/rendering1.png";
import rendering2 from "../assets/gallery/rendering2.png";
import rendering3 from "../assets/gallery/rendering3.png";
import rendering4 from "../assets/gallery/rendering4.jpg";

export default function Hero() {
  return (
    <section className="bg-theme-secondary-700 px-4 py-10 w-full">
      <div className="flex flex-col justify-between lg:flex-row lg:justify-center">
        <div className="">
          <h4 className="text-gray-200 font-bold text-center uppercase">
            Coming soon...
          </h4>
          <div className="h-1 ml-auto bg-theme-primary scale-x-30" />
          <h6 className="text-gray-200 font-semibold mt-2 text-center">
            New location opening in Dunkirk, NY
          </h6>          
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center space-y-2 md:space-y-0 space-x-0 md:space-x-6 mb-2">
        <a href="https://www.choicehotels.com/new-york/dunkirk/clarion-hotels/ny243?mc=llgoxxpx" target="_blank" rel="noreferrer" className="bg-theme-primary w-3/4 md:w-1/4 text-center py-3 rounded border-r-2 border-b-4 shadow-theme-primary-600 hover:bg-theme-primary-400 border-theme-primary-600">
          <button type="button" className="text-theme-secondary-600 font-bold uppercase text-sm md:text-base text-center">
            Stay with Us
          </button>
        </a>   
        <Link to="/employment" className="bg-theme-primary w-3/4 md:w-1/4 text-center py-3 rounded border-r-2 border-b-4 shadow-theme-primary-600 hover:bg-theme-primary-400 border-theme-primary-600">
          <button type="button" className="text-theme-secondary-600 font-bold uppercase text-sm md:text-base">
            Apply Now
          </button>
        </Link>        
        <Link to="/catering" className="bg-theme-primary w-3/4 md:w-1/4 text-center py-3 rounded border-r-2 border-b-4 shadow-theme-primary-600 hover:bg-theme-primary-400 border-theme-primary-600">
          <button type="button" className="text-theme-secondary-600 font-bold uppercase text-sm md:text-base">
            Catering
          </button>
        </Link>    
      </div>
      <div className="grid gap-6 row-gap-5 mb-8 lg:grid-cols-4 sm:row-gap-6 sm:grid-cols-2">
        <Link to="/gallery">
          <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
            <img
              className="object-scale-down w-full h-56 md:h-64 xl:h-80"
              src={rendering1}
              alt=""
            />
            {/* <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
              <p className="mb-4 text-lg font-bold text-gray-100">Mona Lisa</p>
              <p className="text-sm tracking-wide text-gray-300">
              </p>
            </div> */}
          </div>
        </Link>
        <Link to="/gallery">
          <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
            <img
              className="object-scale-down w-full h-56 md:h-64 xl:h-80"
              src={rendering2}
              alt=""
            />
          </div>
        </Link>
        <Link to="/gallery">
          <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
            <img
              className="object-scale-down w-full h-56 md:h-64 xl:h-80"
              src={rendering3}
              alt=""
            />
          </div>
        </Link>
        <Link to="/gallery">
          <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
            <img
              className="object-scale-down w-full h-56 md:h-64 xl:h-80"
              src={rendering4}
              alt=""
            />
          </div>
        </Link>
      </div>
      <div className="text-center">
        <Link to="/gallery" aria-label="To Gallery"
          className="inline-flex items-center font-semibold transition-colors duration-200 text-theme-primary hover:text-theme-primary-800"
        >
          View Gallery
          <svg
            className="inline-block w-3 ml-2"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
          </svg>
        </Link>
      </div>
    </section>
  );
};