import React from "react";
import { Link } from "react-router-dom";
import paper_thumb from "../assets/paper_thumb.png";
import hiring from "../assets/hiring.jpg"
import catering from "../assets/catering.jpg"

export default function Cards() {
  return (
    <section className="bg-scratchy-background bg-cover object-cover bg-center px-6 py-2 md:px-12 md:py-10 flex flex-col space-y-3 md:space-y-0 md:flex-row space-x-0 md:space-x-2 items-center justify-between">      
      <Link to="/catering">
        <div className="max-w-xs p-4 md:p-6 rounded-md shadow-md bg-theme-secondary-300">
          <img src={catering} alt="catering" className="object-contain object-center w-full rounded-md h-40 md:h-72" />
          <div className="mt-2 md:mt-6 md:mb-2">
            <span className="block text-xs font-medium tracking-widest uppercase text-theme-primary">Catering</span>
            <h2 className="text-xl font-semibold tracking-wide">More Information</h2>
          </div>
          <p className="text-gray-200">Find out more about offerings for our team catering your next party or event, available right away</p>
        </div>
      </Link>
      
      <Link to="/employment">
        <div className="max-w-xs p-4 md:p-6 rounded-md shadow-md bg-theme-secondary-300">
          <img src={hiring} alt="hiring" className="object-contain object-center w-full rounded-md h-40 md:h-72" />
          <div className="mt-2 md:mt-6 md:mb-2">
            <span className="block text-xs font-medium tracking-widest uppercase text-theme-primary">Employment</span>
            <h2 className="text-xl font-semibold tracking-wide">Apply for Work</h2>
          </div>
          <p className="text-gray-200">Click here more to contact us about application for positions available at the new location</p>
        </div>
      </Link>

      <a target="_blank" href="https://www.observertoday.com/news/page-one/2021/11/cheers-for-beer-clarion-brewery-moving-forward" rel="noreferrer">
        <div className="max-w-xs p-4 md:p-6 rounded-md shadow-md bg-theme-secondary-300">
          <img src={paper_thumb} alt="" className="object-contain object-center w-full rounded-md h-40 md:h-72" />
          <div className="mt-4 md:mt-6 md:mb-2">
            <span className="block text-xs font-medium tracking-widest uppercase text-theme-primary">In The News</span>
            <h2 className="text-xl font-semibold tracking-wide">November 20, 2021</h2>
          </div>
          <p className="text-gray-200">Article featured in Dunkirk newspaper, Observer Today, detailing plans for new brewery.</p>
        </div>
      </a>
    </section>
  );
};