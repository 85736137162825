import React from "react";

export default function Catering() {
  return (
    <section className="bg-theme-secondary-700 px-4 py-10 w-full">
      <div className="flex items-center justify-center">
        <iframe title="Catering Request" height="865" frameBorder="0" marginHeight="0" marginWidth="0"
          className="min-w-[300px] bg-gray-200 rounded-lg"
          src="https://docs.google.com/forms/d/e/1FAIpQLScVIS-Kj-pqXaEAeJ32V9h7T47B3-gr3M7e0L6RnjPCHF7g1A/viewform?embedded=true">
            Loading…
          </iframe>
      </div>
    </section>
  );
};