import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";

import NavBar from './lib/nav';
import Content from './Content';
import Gallery from './components/Gallery';
import Employment from './components/Employment';
import Catering from './components/Catering';
import Footer from './lib/footer';
import NotFound from "./components/NotFound";

function App() {
  useScrollToTop();
  return (
      <div className="flex flex-col h-screen bg-theme-secondary">
        <main>
          <NavBar />
          <Routes>
            <Route index path="/" element={<Content />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/employment" element={<Employment />} />
            <Route path="/catering" element={<Catering />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
          <Footer />
        </main>
      </div>
  );
}

export default App;


function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};