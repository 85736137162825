import React from "react";
import { Link } from "react-router-dom";
import Icon from "../lib/icon";
import Logo from "../assets/logo.png"

export default function NotFound() {
  return (
    <section>
      <div className="container p-8 flex flex-col md:flex-row justify-center items-center">
        <div className="flex flex-col mb-16 md:mb-0 md:w-3/6">
          <h1 className="mb-4">
            Something went wrong...
            <br className="hidden lg:inline-block" />
          </h1>
          <h3 className="mb-4">
            Sorry about that.
          </h3>
          <p className="mb-8 leading-relaxed text-white">
            The page you attempted to access couldn't be found. Please choose from an option below to navigate away from this issue.
          </p>
          <div className="flex">
            <Link to="#contact" className="inline-flex border-0 py-2 px-6 focus:outline-none rounded text-lg">
              <Icon iconName="HiOutlineArrowSmLeft" className="w-6 h-6" />
              <span>Go Back</span>
            </Link>
            <Link to="/" className="inline-flex ml-2 border-0 py-2 px-6 focus:outline-none rounded text-lg">
              <Icon iconName="HiHome" className="w-6 h-6" />
              <span>Home</span>
            </Link>
            {/* <Link to="/contact" className="inline-flex ml-2 border-0 py-2 px-6 focus:outline-none rounded text-lg">
            <Icon iconName="HiMail" className="w-6 h-6" />
              <span>Contact</span>
            </Link> */}
          </div>
        </div>
        <div className="w-2/6">
          <img alt="Steelbound Logo" src={Logo} />
        </div>
      </div>
    </section>
  );
}