import React from "react";
import Icon from "./icon";

export default function Loading(props) {

  return (
    <div id="loading-screen" className="w-full h-full fixed block top-0 left-0 bg-gray-300 opacity-75 z-50">
      <div className="text-hel-teal-800 top-1/3 relative w-full h-full flex">
        <Icon iconName="FaCircleNotch" animate={true} className="w-full text-4xl" />
        <span className="w-full text-center text-bold pt-10 text-4xl">{props.text || "Loading..."}</span>
      </div>
    </div>
  );
}