import React from "react";

export default function Employment() {
  return (
    <section className="bg-theme-secondary-700 px-4 py-10 w-full">
      <div className="flex items-center justify-center">
        <iframe title="Job Application" height="1800" frameBorder="0" marginHeight="0" marginWidth="0"
          className="min-w-[300px] bg-gray-200 rounded-lg"
          src="https://docs.google.com/forms/d/e/1FAIpQLSfHlqmxq_GSP1LPfIkD5RlyS0y5lzyEO1vkGdWevT7KEuV7cA/viewform?embedded=true">Loading…</iframe>
      </div>
    </section>
  );
};