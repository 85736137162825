import React from "react";

export default function Map() {
  return (
    <section className="relative text-center">
      <div className="w-full h-72 md:h-full md:absolute inset-0 bg-gray-300">
        <iframe width="100%" height="100%" frameBorder="0" marginHeight="0" marginWidth="0" title="map" scrolling="no" src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=30%20Lake%20Shore%20Dr%20E,%20Dunkirk,%20NY%2014048&ie=UTF8&t=&z=14&iwloc=B&output=embed"></iframe>
      </div>
      <div className="container px-2 md:px-5 py-6 md:py-24 flex justify-center">
        <div className="w-11/12 md:w-1/3 bg-theme-secondary-300 rounded-lg p-4 md:p-6 flex flex-col md:ml-auto relative z-10 shadow-md">
          <h2 className="text-theme-primary text-xs md:text-xl mb-1 font-bold">Find Us</h2>
          <p className="leading-relaxed mb-5 text-white">You're this close to Dunkirk's newest dining experience</p>
          <p className="leading-relaxed text-white">Click the button in the upper left-hand corner of the map, enter your address, and get your personal directions to visit Dunkirk's newest restaurant and hotel - Steelbound Dunkirk!</p>
        </div>
      </div>
    </section>
  );
};