import React from "react";
import { Link } from "react-router-dom";
import wordmark from "../assets/wordmark.png";

export default function NavBar() {
  return (
    <header className="w-full p-4 bg-theme-secondary">
      <div className="flex justify-center">
        <Link rel="noopener noreferrer" to="/" aria-label="Back to homepage">
          <img src={wordmark} alt="Steelbound Brewery" className="h-8 md:h-16" />
        </Link>
      </div>
    </header>
  );
}